.Secondary-navbar{
    position: fixed;
    top: 92px;
    background-color: #ededed;
    width: 100%;
    padding: 8px 38px;
    z-index: 99;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.Secondary-navbar a{
    color: #666666 ;
    text-decoration: none;
    font-weight: 500;
    padding: 0 9px;
    font-size: 14px;
}

.Secondary-navbar a.active{
    font-weight: 800;
}
.disabled-link{
    pointer-events: none;

}
@media screen and (max-width:602px){
    .Secondary-navbar{
        top: 87px;
        padding: 6px 24px;
    }
}


@media screen and (max-width:769px){
    .Secondary-navbar{
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: scroll;
    }
}